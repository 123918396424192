var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.chapter)?_c('div',{staticClass:"chapter-detail"},[_c('div',{class:{
      'chapter-detail__img': true,
      'filter-img':
        (!_vm.user.sim_info.simulator_paid && _vm.chapter.price != 0 && _vm.simInfo.price != 0) ||
        (!_vm.chapter.order_active && _vm.simInfo.order_lesson) ||
        !_vm.chapter.active,
    },style:({
      background:
        'url(' + _vm.chapter.picture.replace(/^http:\/\//i, "https://") + ') center center',
    })}),_c('h2',{staticClass:"chapter-detail__title",domProps:{"innerHTML":_vm._s(_vm.chapter.name)}}),_c('div',{staticClass:"chapter-detail__description",domProps:{"innerHTML":_vm._s(_vm.chapter.description)}}),(_vm.isLogin)?_c('div',{staticClass:"chapter-detail__start"},[_c('FormButton',{attrs:{"text":_vm.getButtonWord,"inactive":(!_vm.user.sim_info.simulator_paid && _vm.chapter.price != 0 && _vm.simInfo.price != 0) ||
        (!_vm.chapter.order_active && _vm.simInfo.order_lesson) ||
        !_vm.chapter.active,"color":_vm.getColor,"size":"md"},on:{"click":_vm.openPage}})],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }